import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { useSettings as useAccountSettings } from '@guider-global/front-end-hooks';
import {
  getLanguageFromCMS,
  getSubDomain,
  getTimeFormat,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
  useSettings,
} from '@guider-global/sanity-hooks';
import { INotification } from '@guider-global/shared-types';
import {
  NotificationBubbleView,
  NotificationCardView,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';
import { useEffect, useRef } from 'react';
import { getSessionDateAndDuration } from 'utils/getSessionDateAndDuration';
import { NotificationBubbleDateLabel } from './NotificationBubbleDateLabel';
import { parseNotificationMetaData } from './parseNotificationMetaData';

export type UsePictureParams = {
  pictureId: string;
  sasTokenApiPath?: string;
  containerName?: string;
  pictureUrl?: string;
  pictureBlobName?: string;
};
export type NotificationCardContainerProps = {
  notification: INotification;
  onVisible?: () => void;
};

export function NotificationCardContainer({
  notification,
  onVisible,
}: Readonly<NotificationCardContainerProps>) {
  const cardRef = useRef<HTMLDivElement | null>(null);

  // Sanity
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { getProfileImage } = useProfileImage();
  const { programs } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  const { settings } = useSettings({});

  const { settings: accountSettings } = useAccountSettings({
    options: { keepPreviousData: true },
  });
  const timezone = accountSettings?.at(0)?.timezone ?? 'Europe/London';

  const defaultAvatar = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.default_user_avatar ?? '',
  });
  // BseLanguage
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const locale = getLanguageFromCMS(baseLanguage?.language_code);
  const formattedTime = getTimeFormat(baseLanguage?.time_format);

  // Parse MetaData
  const metaDataParseResult = parseNotificationMetaData(
    notification.eventType,
    notification.metaData,
  );
  const metaData = metaDataParseResult.success
    ? metaDataParseResult.data
    : undefined;
  useEffect(() => {
    if (metaDataParseResult.success) return;
    const { error } = metaDataParseResult;
    console.error('Failed to parse notificaiton metadata', {
      notification,
      error,
    });
  }, [metaDataParseResult, notification]);

  // Build Avatar
  function getAvatarParams(): UsePictureParams[] {
    if (!metaData?.avatar) return [];
    return [
      {
        pictureId: notification.id,
        sasTokenApiPath: '/admin/storage',
        containerName: 'pictures',
        pictureUrl: undefined,
        pictureBlobName: metaData.avatar,
      },
    ];
  }
  const avatarParams = getAvatarParams();

  const avatars: UsePictureParams[] = avatarParams.map((params) => {
    const { pictureId, sasTokenApiPath, containerName, pictureBlobName } =
      params;
    const blobPicture = pictureBlobName ? pictureBlobName : undefined;
    return {
      pictureId,
      blobPicture,
      sasTokenApiPath,
      containerName,
      pictureBlobName,
    };
  });
  const plainUrl = metaData?.avatar;
  const avatarSrc =
    avatars.map((avatar) => {
      const { pictureBlobName } = avatar;
      return getProfileImage({
        profilePicture: pictureBlobName,
        userPicture: plainUrl,
      });
    })[0] || defaultAvatar;

  // Organization

  const { organization } = useOrganization({
    organizationSlug,
    localeCode,
  });

  const goalCategories = organization?.goal_categories?.categories?.map(
    (goalCategory) => ({
      label: goalCategory.goal_category_name,
      slug: goalCategory.goal_category_slug.current,
      icon: goalCategory.goal_category_icon?.name,
    }),
  );

  // Common NotificationCard Props
  const programSlug = notification.programSlug;
  const program = programSlug
    ? programs.find((program) => program.metadata.id.current === programSlug)
    : undefined;
  const programName = program?.metadata.program_name;
  const programTypeCommon =
    program?.program_details?.program_type?.program_type_text?.common;
  const programTypeVerb = programTypeCommon?.verb ?? 'Mentoring';
  const programTypeNoun = programTypeCommon?.noun ?? 'Mentor';
  const unread = notification.status === 'unread';
  const date = notification.createdAt
    ? format(new Date(notification.createdAt), 'dd/MM/yy')
    : undefined;

  const commonProps = {
    cardRef,
    unread,
    date,
    caption: programName,
  };
  // MUI
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      threshold: 1,
    };
    const handleObserver: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];

      if (entry.isIntersecting && onVisible) {
        onVisible();
      }
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Render
  switch (metaData?.eventType) {
    case undefined: {
      return <></>;
    }
    // TODO: Uncomment once 'relationships-individual-unread-message' is implemented
    // case 'relationships-individual-unread-message': {
    //   const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
    //     metaData;

    //   return (
    //     <NotificationCardView
    //       date={date}
    //       avatarSrc={avatarSrc}
    //       heading={interpolate(
    //         baseLanguage.notifications.individual_relationships
    //           .relationships_individual_unread_message.description,
    //         {
    //           counterpartyFirstName,
    //           counterpartyLastName,
    //           programTypeVerb,
    //           programTypeNoun,
    //         },
    //       )}
    //       caption={programName}
    //       linkLabel={
    //         baseLanguage.notifications.individual_relationships
    //           .relationships_individual_unread_message.button_label
    //       }
    //       linkHref={relationshipUrl}
    //     ></NotificationCardView>
    //   );
    // }
    case 'relationships-individual-schedule-session': {
      const { counterpartyFirstName, counterpartyLastName, createSessionUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_schedule_session?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkHref={createSessionUrl}
          linkLabel={
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_schedule_session?.button_label
          }
        />
      );
    }
    case 'relationships-individual-upcoming-session': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_upcoming_session?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="success"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage?.globals?.sessions?.view_session ?? ''}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
              timezone,
              formattedTime,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-updated': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
        oldSessionStart,
        oldSessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_session_updated?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="warning"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage?.globals?.sessions?.view_session ?? ''}
            body={getSessionDateAndDuration({
              sessionStart,
              sessionEnd,
              locale,
              timezone,
              formattedTime,
            })}
            strikethroughBody={
              oldSessionStart !== sessionStart || oldSessionEnd !== sessionEnd
                ? getSessionDateAndDuration({
                    sessionStart: oldSessionStart,
                    sessionEnd: oldSessionEnd,
                    locale,
                    timezone,
                    formattedTime,
                  })
                : undefined
            }
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-deleted': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        oldSessionName,
        oldSessionStart,
        oldSessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_session_deleted?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="error"
            label={
              <NotificationBubbleDateLabel show={isMd} date={oldSessionStart} />
            }
            heading={oldSessionName}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
              timezone,
              formattedTime,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-relationship-created': {
      const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_relationship_created?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkLabel={
            baseLanguage?.globals?.relationships?.view_relationships ??
            'View Relationship'
          }
          linkHref={relationshipUrl}
        />
      );
    }
    case 'relationships-individual-relationship-created-by-admin': {
      const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_relationship_created_by_admin
              ?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkLabel={
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_relationship_created_by_admin
              ?.button_label
          }
          linkHref={relationshipUrl}
        />
      );
    }
    case 'relationships-individual-relationship-concluded': {
      const { counterpartyFirstName, counterpartyLastName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_relationship_concluded?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        />
      );
    }
    case 'relationships-individual-relationship-concluded-by-admin': {
      const { counterpartyFirstName, counterpartyLastName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_relationship_concluded_by_admin
              ?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        />
      );
    }

    case 'relationships-individual-relationship-shared-goal-updated': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        goalCategory,
        goalObjective,
        manageGoalsUrl,
      } = metaData;

      const goalCategoryLabel = goalCategories?.find(
        (category) => category.slug === goalCategory,
      )?.label;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_shared_goal_updated?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color={'info'}
            label={goalCategoryLabel}
            body={goalObjective}
            bodyColor={theme.palette.text.primary}
            bodyVariant="body1"
            linkHref={manageGoalsUrl}
            linkLabel={baseLanguage?.globals?.goals?.view_goal_button_label}
          />
        </NotificationCardView>
      );
    }
    // TODO: Uncomment once 'relationships-group-unread-message' is implemented
    // case 'relationships-group-unread-message': {
    //   const { groupName, relationshipUrl } = metaData;

    //   return (
    //     <NotificationCardView
    //       date={date}
    //       avatarSrc={avatarSrc}
    //       heading={interpolate(
    //         baseLanguage?.notifications.group_relationships
    //           .relationships_group_unread_message.description,
    //         {
    //           groupName,
    //         },
    //       )}
    //       caption={programName}
    //       linkLabel={
    //         baseLanguage?.notifications.group_relationships
    //           .relationships_group_unread_message.button_label
    //       }
    //       linkHref={relationshipUrl}
    //     ></NotificationCardView>
    //   );
    // }

    case 'reviews-submitted': {
      const { traineeDisplayName, reviewPageUrl } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.reviews?.review_received?.title,
            {
              displayName: traineeDisplayName,
            },
          )}
          linkLabel={
            baseLanguage?.notifications?.reviews?.review_received?.button_label
          }
          linkHref={reviewPageUrl}
        />
      );
    }

    case 'relationships-group-schedule-session': {
      const { guideFirstName, guideLastName, groupName, createSessionUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_schedule_session?.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
          linkLabel={baseLanguage?.globals?.sessions?.schedule_session}
          linkHref={createSessionUrl}
        />
      );
    }
    case 'relationships-group-session-deleted': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        oldSessionName,
        oldSessionStart,
        oldSessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_session_deleted?.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="error"
            label={
              <NotificationBubbleDateLabel show={isMd} date={oldSessionStart} />
            }
            heading={oldSessionName}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
              timezone,
              formattedTime,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-session-updated': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        sessionName,
        sessionStart,
        sessionEnd,
        oldSessionStart,
        oldSessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_session_updated?.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="warning"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage?.globals?.sessions?.view_session}
            body={getSessionDateAndDuration({
              sessionStart,
              sessionEnd,
              locale,
              timezone,
              formattedTime,
            })}
            strikethroughBody={
              oldSessionStart !== sessionStart || oldSessionEnd !== sessionEnd
                ? getSessionDateAndDuration({
                    sessionStart: oldSessionStart,
                    sessionEnd: oldSessionEnd,
                    locale,
                    timezone,
                    formattedTime,
                  })
                : undefined
            }
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-upcoming-session': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        sessionName,
        sessionStart,
        sessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_upcoming_session?.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="success"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage?.globals?.sessions?.view_session}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
              timezone,
              formattedTime,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-relationship-guide-concluded': {
      const { guideFirstName, guideLastName, groupName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_relationship_guide_concluded?.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        />
      );
    }
    case 'relationships-group-relationship-trainee-left': {
      const { traineeFirstName, traineeLastName, groupName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_trainee_left_group?.description,
            {
              traineeFirstName,
              traineeLastName,
              groupName,
            },
          )}
          linkLabel={
            baseLanguage?.notifications?.group_relationships
              ?.relationships_group_trainee_left_group?.button_label
          }
          linkHref={relationshipUrl}
        />
      );
    }
    case 'relationships-individual-session-accepted': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_session_accepted?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
            },
          )}
        >
          <NotificationBubbleView
            color="primary"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
              timezone,
              formattedTime,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-tentatively-accepted': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_session_tentatively_accepted
              ?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
            },
          )}
        >
          <NotificationBubbleView
            color="primary"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
              timezone,
              formattedTime,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-declined': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationships_individual_session_declined?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
            },
          )}
        >
          <NotificationBubbleView
            color="primary"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
              timezone,
              formattedTime,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-relationship-auto-concluded': {
      const { counterpartyFirstName, counterpartyLastName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.relationship_automatically_concluded?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
            },
          )}
          linkLabel={
            baseLanguage?.globals?.programs?.view_programs_button_label ??
            'View Programmes'
          }
          linkHref="/programs"
        />
      );
    }
    case 'relationships-individual-relationship-reengage': {
      const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage?.notifications?.individual_relationships
              ?.auto_conclude_relationship?.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
            },
          )}
          linkLabel={
            baseLanguage?.globals?.relationships?.view_relationships ??
            'View Relationship'
          }
          linkHref={relationshipUrl}
        />
      );
    }
    default: {
      return <></>;
    }
  }
}
